import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import stdurl from 'url'
import { telLink } from '@raywhite/helpers-utils/lib/helpers/link'
import { cleanMarkup, plainText, depressify } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { excerptify, toSlug } from '@raywhite/helpers-utils/lib/helpers/string'
import { getCountryFromCode } from '@raywhite/data-utils/lib/data/member/address'
import { getStructuredData, officeUrl } from '@raywhite/data-utils/lib/data/member/office'
import NotFoundPage from './NotFoundPage.jsx'
import Link from './Link.jsx'
import TestimonialList from './TestimonialList.jsx'
import SocialIcons from './SocialIcons.jsx'
import Loader from './Loader.jsx'
import JsonLD from './JsonLD.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import OpenGraph from './OpenGraph.jsx'

import AgentsPage from '../container/AgentsPage.jsx'
import AwardsList from './AwardsList.jsx'

export const OfficeOpenGraph = props => {
  const {
    baseUrl,
    isPrimary,
    office,
    content,
    siteTitle,
    image,
  } = props

  const {
    fullName,
    description,
    phone,
    promo,
    fax,
    email,
    address: {
      latitude,
      longitude,
      streetAddress,
      locality,
      stateCode,
      state,
      country,
      postCode,
    } = {},
    roles = [],
    imageExterior,
    imageReception,
    imageStaff,
  } = office
  const isVirtual = roles.indexOf('Virtual Office') !== -1
  const contactData = {
    streetAddress,
    locality,
    region: stateCode || state,
    postalCode: postCode,
    countryName: getCountryFromCode(country) || country,
    email,
    faxNumber: fax,
    phoneNumber: promo || phone,
    website: baseUrl,
  }
  if (isVirtual) {
    delete contactData.streetAddress
    delete contactData.locality
    delete contactData.region
    delete contactData.postalCode
  }

  return (
    <OpenGraph
      type="business.business"
      url={baseUrl && `${baseUrl}${officeUrl(office, isPrimary)}`}
      title={(isPrimary && siteTitle) ? siteTitle : fullName}
      siteName={siteTitle || fullName}
      description={content || description}
      place={isVirtual ? undefined : { location: { latitude, longitude } }}
      business={{ contactData }}
      mayCropImage
      image={{ url: image || imageStaff || imageExterior || imageReception }}
    />
  )
}
OfficeOpenGraph.propTypes = {
  baseUrl: PropTypes.string,
  isPrimary: PropTypes.bool.isRequired,
  office: PropTypes.object.isRequired,
  content: PropTypes.string,
  siteTitle: PropTypes.string,
  image: PropTypes.string,
}

const hostname = website => {
  if (!website) return undefined
  const host = /^https?:\/\//.test(website)
    ? stdurl.parse(website).hostname
    : website
  return host.replace(/^www\./, '')
}

const OfficeContact = ({
  org,
  isMultiple,
  baseUrl,
  header = 'Contact Us',
  showSocial = true,
  showEnquiryModal,
}) => {
  const {
    loaded,
    notFound,
    fullName,
    organisationId,
    phone,
    primaryPhone,
    promo,
    fax,
    email,
    address,
    postalAddress = {},
    website,
    roles = [],
  } = org
  if (!loaded || notFound) return null

  const isVirtual = roles.indexOf('Virtual Office') !== -1
  const link = isMultiple ? `/about/${toSlug(fullName)}/${organisationId}` : '/about'
  const showWebsite = website && hostname(website) !== hostname(baseUrl)
  const showPhone = primaryPhone || phone

  return (
    <div className="aboutpg_contact">
      {!!header && <Link to={link}><h3 className="foxtrot">{header}</h3></Link>}
      <ul>
        {!!promo && (
          <li className="aboutpg_contact_promo">
            <span className="aboutpg_contact_label muted">Free Phone:</span>
            <span className="aboutpg_contact_value">
              {telLink(promo, { 'data-ev-label': fullName })}
            </span>
          </li>
        )}
        {!!showPhone && (
          <li className="aboutpg_contact_landline">
            <span className="aboutpg_contact_label muted">Phone:</span>
            <span className="aboutpg_contact_value">
              {telLink(showPhone, { 'data-ev-label': fullName })}
            </span>
          </li>
        )}
        {!!fax && (
          <li className="aboutpg_contact_fax">
            <span className="aboutpg_contact_label muted">Fax:</span>
            <span className="aboutpg_contact_value">{fax}</span>
          </li>
        )}
        {!!email && (
          <li className="aboutpg_contact_email">
            <span className="aboutpg_contact_label muted">Email:</span>
            <span className="aboutpg_contact_value">
              <a onClick={showEnquiryModal}>{email}</a>
            </span>
          </li>
        )}
        {showWebsite && (
          <li className="aboutpg_contact_website">
            <span className="aboutpg_contact_label muted">Website:</span>
            <span className="aboutpg_contact_value">
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {hostname(website)}
              </a>
            </span>
          </li>
        )}
        {!isVirtual && address && !!address.streetAddress && (
          <li className="aboutpg_contact_address">
            <span className="aboutpg_contact_label muted">Address:</span>
            <span className="aboutpg_contact_value">
              {(
                address.floorNumber
                  ? [
                    /^\d+$/.test(address.floorNumber) ? 'Level ' : '',
                    address.floorNumber,
                    <br key="floor" />,
                  ]
                  : null
              )}
              {address.streetAddress}
              <br />
              {address.locality.trim()},
              {' '}
              {address.stateCode || address.state}
              {' '}
              {address.postCode}
            </span>
          </li>
        )}
        {!!postalAddress.streetAddress && (
          <li className="aboutpg_contact_postal">
            <span className="aboutpg_contact_label muted">Postal Address:</span>
            <span className="aboutpg_contact_value">
              {postalAddress.floorNumber ? [/^\d+$/.test(postalAddress.floorNumber) ? 'Level ' : '', postalAddress.floorNumber, <br />] : null}
              {postalAddress.streetAddress}
              <br />
              {postalAddress.locality.trim()},
              {' '}
              {postalAddress.stateCode || postalAddress.state}
              {' '}
              {postalAddress.postCode}
            </span>
          </li>
        )}
      </ul>
      {showSocial && <SocialIcons social={org} className="social circle" />}
    </div>
  )
}
OfficeContact.propTypes = {
  org: PropTypes.object.isRequired,
  header: PropTypes.string,
  showSocial: PropTypes.bool,
  isMultiple: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  showEnquiryModal: PropTypes.func.isRequired,
}

const AwardsLogoBlock = ({
  awards,
  recognitionLevels,
  showAwards,
}) => {
  const hasAwards = Boolean(awards.length)
  const logos = recognitionLevels.filter(r => r.webLogoUrl)
  const hasLogos = Boolean(logos.length)

  if (!(showAwards && hasAwards) && !hasLogos) return null

  return (
    <div>
      {showAwards && <h3 className="foxtrot">Awards</h3>}
      {logos.map((r) => (
        <div className="elite" key={r.webLogoUrl}>
          <img
            src={r.webLogoUrl}
            alt="Award"
          />
        </div>
      ))}
      {showAwards && hasAwards && (
        <div className="office_awards" id="awards">
          <AwardsList awards={awards} />
        </div>
      )}
    </div>
  )
}
AwardsLogoBlock.propTypes = {
  awards: PropTypes.arrayOf(PropTypes.shape({})),
  recognitionLevels: PropTypes.arrayOf(PropTypes.shape({
    webLogoUrl: PropTypes.string.isRequired,
  })),
  showAwards: PropTypes.bool.isRequired,
}
AwardsLogoBlock.defaultProps = {
  awards: [],
  recognitionLevels: [],
}

export default class OfficePage extends Component {
  static propTypes = {
    org: PropTypes.object.isRequired,
    showAwards: PropTypes.bool.isRequired,
    baseUrl: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    content: PropTypes.string,
    contactOrganisations: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteTitle: PropTypes.string,
    showEnquiryModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contactOrganisations: [],
    showAwards: true,
  }

  render() {
    const {
      baseUrl,
      isPrimary,
      org,
      content,
      contactOrganisations,
      siteTitle,
      showEnquiryModal,
      showAwards,
    } = this.props

    const {
      loaded,
      notFound,
      fullName,
      description,
      testimonials = [],
      twitter,
      organisationId,
      recognitionLevels = [],
      awards = [],
    } = org

    const isMultiple = contactOrganisations.length > 0
    const _description = content || description
    const body = _description && (
      content ? depressify(_description) : cleanMarkup(_description)
    )

    if (notFound) {
      return <NotFoundPage />
    }

    if (!loaded) {
      return <Loader />
    }

    const structuredData = getStructuredData(
      org,
      isPrimary,
      baseUrl,
      { nameOverride: isPrimary ? siteTitle : undefined },
    )

    return (
      <div className="aboutpg">
        <Helmet>
          <title>{isPrimary ? 'About Us' : (org.fullName || 'Office')}</title>
          {!!_description && (
            <meta
              name="description"
              content={excerptify(plainText(_description), 160)}
            />
          )}
        </Helmet>
        <TwitterSummaryCard
          type="summary"
          site={twitter}
          title={structuredData.name}
          description={_description}
        />
        <JsonLD>{structuredData}</JsonLD>
        <OfficeOpenGraph
          baseUrl={baseUrl}
          office={org}
          isPrimary={isPrimary}
          content={content}
          siteTitle={siteTitle}
        />
        <div className="inner">
          <div className="pg_header">
            <span className="mini muted">About</span>
            <h1 className="charlie centered_text">
              {(isPrimary && siteTitle) ? siteTitle : fullName}
            </h1>
          </div>
          <div className="aboutpg_content_wrap">
            <div className="aboutpg_contacts">
              <OfficeContact
                key={organisationId}
                isMultiple={isMultiple}
                org={org}
                header={contactOrganisations.length ? org.fullName : undefined}
                baseUrl={baseUrl}
                showEnquiryModal={showEnquiryModal}
              />
              <AwardsLogoBlock
                awards={awards}
                recognitionLevels={recognitionLevels}
                showAwards={showAwards}
              />
              {contactOrganisations.map(_org => (
                <React.Fragment key={_org.organisationId}>
                  <OfficeContact
                    isMultiple={isMultiple}
                    header={_org.fullName}
                    showSocial={false}
                    org={_org}
                    baseUrl={baseUrl}
                    showEnquiryModal={showEnquiryModal}
                  />
                  <AwardsLogoBlock
                    awards={_org.awards}
                    recognitionLevels={_org.recognitionLevels}
                    showAwards={showAwards && !isMultiple}
                  />
                </React.Fragment>
              ))}

            </div>
            <div className="aboutpg_content">
              {!!body && <div dangerouslySetInnerHTML={body} />}
            </div>
          </div>
          {!!testimonials.length && (
            <hr />
          )}
        </div>
        {!!testimonials.filter(t => t.detail).length && (
          <div className="aboutpg_testis_wrap inner_sm">
            <div className="aboutpg_testis">
              <span className="mini muted">What They Say</span>
              <h2 className="charlie centered_text">Testimonials</h2>
              <TestimonialList testimonials={testimonials} />
            </div>
          </div>
        )}
        <div className="aboutpg_agents" id="team">
          <AgentsPage
            headerElement="h2"
            hideIfNoAgents
          />
        </div>
      </div>
    )
  }
}
