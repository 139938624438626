import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import queryString from 'query-string'
import {
  getOrganisation,
  getOrganisations,
  loadOrganisations,
} from '@raywhite/redux/lib/org'
import { loadMembers, getOrganisationMembers } from '@raywhite/redux/lib/member'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { sortAgents } from '@raywhite/data-utils/lib/data/member/agent'
import { pluralize } from '@raywhite/data-utils/lib/data/listing/utils'
import { getBrokers, loadBrokers } from '../../redux/modules/brokers'
import AgentList from '../presentational/AgentList.jsx'
import AgentCard from '../presentational/AgentCard.jsx'
import withContext from '../hocs/withContext'
import Loader from '../presentational/Loader.jsx'

class AgentsPage extends Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    organisationIds: PropTypes.array.isRequired,
    displayOrganisationIds: PropTypes.array.isRequired,
    organisations: PropTypes.object.isRequired,
    hiddenAgents: PropTypes.array.isRequired,
    agentOrder: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    newAgents: PropTypes.oneOf(['show', 'hide']),
    showOfficeDetails: PropTypes.bool.isRequired,
    showHiddenOffices: PropTypes.bool.isRequired,
    primaryOffice: PropTypes.object.isRequired,
    includeBrokers: PropTypes.bool,
    getBrokers: PropTypes.func,
    brokers: PropTypes.array,
    headerElement: PropTypes.oneOf(['h1', 'h2']),
    hideIfNoAgents: PropTypes.bool,
  }

  static defaultProps = {
    headerElement: 'h1',
    hideIfNoAgents: false,
  }

  static fetchData = (dispatch, params, settings) => {
    const {
      getMembers,
      organisationIds,
      getOrganisations: _getOrganisations,
    } = settings

    return unlazyAll([
      dispatch(loadMembers(getMembers, organisationIds)),
      dispatch(loadOrganisations(_getOrganisations, organisationIds)),
    ])
  }

  componentDidMount() {
    const {
      dispatch,
      getMembers,
      organisationIds,
      brokers,
      includeBrokers,
      getBrokers: getBrokersApi,
      primaryOffice: {
        countryCode,
      }
    } = this.props

    const brokerIds = brokers.map(({ brokerId }) => brokerId)

    dispatch(loadMembers(getMembers, organisationIds))

    if (countryCode && includeBrokers) {
      dispatch(loadBrokers(getBrokersApi, countryCode.toLowerCase(), brokerIds))
    }
  }

  /**
   * Get members after applying filtering and sorting.
   */
  filterMembers() {
    const { loaded, members, hiddenAgents, agentOrder, newAgents } = this.props

    if (!loaded) {
      return []
    }

    // Remove duplicate agents
    const unique = [...new Set(members)]

    // Only show non-hidden agents
    const filtered = unique.filter(
      member => !hiddenAgents.includes(member.memberId)
    )

    // Index by ID
    const mapped = filtered.reduce((result, member) => {
      result[member.memberId] = member // eslint-disable-line no-param-reassign
      return result
    }, {})

    // Get those with explicit order
    const ordered = agentOrder
      .map(memberId => mapped[memberId])
      .filter(x => !!x)

    // New agents have no order, so we can ignore them if they're being hidden
    if (newAgents === 'hide') return ordered

    // Get those without explicit order
    const unordered = filtered
      .filter(member => !~ordered.indexOf(member))
      .sort(sortAgents)

    // Return the lot
    return ordered.concat(unordered)
  }

  render() {
    const {
      brokers,
      loaded,
      organisationIds,
      displayOrganisationIds,
      organisations,
      includeBrokers,
      primaryOffice: {
        fullName: officeName,
      },
      headerElement: HeaderElement,
      hideIfNoAgents,
    } = this.props

    const members = this.filterMembers()
    if (!members.length && hideIfNoAgents) return null

    const uniqueOrgIds = [...new Set(members.map(
      member => member.organisationIds.reduce((res, id) => {
        if (res) return res
        return organisations[id] ? id : undefined
      }, undefined)
    ))]
    // Only show org details if there are multiple orgs to show
    const showOrgDetails = uniqueOrgIds.length > 1

    const content = loaded
      ? (
        <AgentList
          members={members}
          organisationIds={displayOrganisationIds}
          organisations={showOrgDetails ? organisations : {}}
        />
      )
      : <Loader />

    const brokerToMember = broker => ({
      fullName: `${broker.firstName} ${broker.lastName}`,
      alternateName: undefined,
      email: `${broker.email}`,
      titles: { [organisationIds[0]]: broker.title },
      mobilePhone: `${broker.mobile}`,
      officePhone: `${broker.phone}`,
      promoPhone: `${broker.phone}`,
      imageHeadshot: `${broker.image}`,
      phones: {},
      organisationIds,
      organisations: {
        [organisationIds[0]]: {
          id: organisationIds,
          membershipId: `${broker.brokerId}`,
          name: officeName,
          title: broker.title,
          public: true
        }
      }
    })

    const brokerLabel = pluralize(brokers.length, brokers[0]?.title || '', `${brokers[0]?.title}s`)
    const utms = queryString.stringify({
      utm_source: 'raywhite',
      utm_medium: 'referral',
      utm_campaign: 'officewebsite',
      utm_term: 'team',
      utm_content: 'brokercard',
    })

    return (
      <article className="pg_agents">
        <div className="inner_lg">
          <div className="pg_header">
            <span className="mini muted preheading">About Us</span>
            <HeaderElement className="charlie centered_text">Our Team</HeaderElement>
          </div>
          {content}
          {includeBrokers && !!brokers && !!brokers.length && ([
            <div
              key="header"
              className="pg_header"
            >
              <HeaderElement className="charlie centered_text">
                Our
                {' '}
                {brokerLabel}
              </HeaderElement>
            </div>,
            <ul
              key="bokers"
              data-agents={brokers.length}
            >
              {brokers.filter(b => b.loaded && !b.error).map(broker => (
                <li key={broker.brokerId}>
                  <Link
                    to={`${broker.website}?${utms}`}
                    target="_blank"
                    rel="noopene"
                  >
                    <AgentCard
                      organisationIds={organisationIds}
                      organisations={organisations}
                      member={brokerToMember(broker)}
                      showContactDetails={false}
                      isStatic={false}
                    />
                  </Link>
                </li>
              ))}
            </ul>,
          ])}
        </div>
      </article>
    )
  }
}

function mapStateToProps(state) {
  const members = getOrganisationMembers(state.members, state.config.organisationIds)
  const brokers = getBrokers(state.brokers, state.config.options.brokers.ids)
  const {
    organisationIds,
    displayOrganisationIds,
    options: {
      agents,
      showOfficeDetails,
      showHiddenOffices,
    },
  } = state.config

  return {
    organisationIds,
    displayOrganisationIds,
    agentOrder: agents.sort || [],
    hiddenAgents: agents.hidden || [],
    newAgents: agents.newAgents || 'show',
    loaded: members.loaded,
    members: members.entities,
    organisations: showOfficeDetails
      ? getOrganisations(
        state.orgs, showHiddenOffices ? organisationIds : displayOrganisationIds
      ).entities
      : {},
    includeBrokers: state.config.options.brokers.showInTeam,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    showOfficeDetails,
    showHiddenOffices,
    brokers: brokers.entities.filter(broker => !broker.notFound),
  }
}

export default (
  connect(mapStateToProps)(withContext('getMembers', 'getOrganisations', 'getBrokers')(AgentsPage))
)
